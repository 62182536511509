.wpo-section-title,
.wpo-section-title-s2 {
	margin-bottom: 60px;
	text-align: center;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: capitalize;
		font-size: 20px;
		color: $theme-primary-color-s2;
		font-family: $base-font;

		@include media-query(575px) {
			font-size: 16px;
		}
	}

	h2 {
		font-size: 40px;
		line-height: 55px;
		margin: 0;
		margin: 15px 0;
		position: relative;
		// text-transform: uppercase;
		font-family: $custom-heading-font;
		font-weight: 500;


		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 22px;
		}

	}

	p{
		font-size: 18px;
	}

	.section-title-img{
        max-width: 200px;
		margin: 0 auto;
		position: relative;

		&::before,
		&::after{
			position: absolute;
			left: -70px;
			top: 50%;
			transform: translateY(-50%);
			content: "";
			width: 100px;
			height: 1px;
			background: $theme-primary-color;

			@include media-query(575px) {
				width: 60px;
				left: -20px;
			}
		}
		&::after{
			right: -70px;
			left:auto;

			@include media-query(575px) {
				right: -20px;
			}
		}
	}

}


.wpo-section-title-s2 {
	.section-title-img{
		.round-ball{
			position: absolute;
			left: 50%;
			width: 15px;
			height: 15px;
			border: 1px solid  $theme-primary-color;
			border-radius: 50%;
			transform: translateX(-50%);
			top: -5px;
		}

		&:after,&:before{
			width: 144px;

			@include media-query(575px) {
				width: 80px;
			}
		}
	}
}