// styles/Congratulations.module.scss
@keyframes pop {
  0% {
      transform: scale(0.5);
      opacity: 0;
  }
  50% {
      transform: scale(1.2);
      opacity: 1;
  }
  100% {
      transform: scale(1);
  }
}
  
.congratulations {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px;
  background: #ffcc00;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  text-align: center;
  animation: pop 0.5s ease;

  h2, p {
      margin: 0;
      padding: 0;
  }
}
  